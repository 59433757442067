.center_align {
  text-align: center;
}


.map_area{

}

.order_sum{
  padding-top: 10px;
}

.leaflet-container{
  height:250px;
  filter: saturate(10%) ;
}

.footer{
  display: flex;
  width:100%;
  margin: 2px 2px 2px 2px;
  justify-content: flex-start;
}

.place{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.place{
  width:100%;
  height:110px;
  padding: 20px 5px 5px 5px
}

.place_icon{
  display: inline-block;
  width:80px;
}
.place_addr{
  display: inline-block;
}



@media only screen and (max-width: 600px) {
  .place{
    width:100%;
    height:110px;
    padding: 2px 2px 2px 2px
  }
  .place_icon{
    display:none;
  }
  .place_addr{
    font-size: 15px;
    padding: 4px 4px 4px 4px;
  }
}
